<template>
  <main class="page-users page-list-user">
    <PageTitleContainer>
      <PageTitle removeMargin>Utenti</PageTitle>

      <div v-if="isAdmin" class="text-right mb-3">
        <router-link class="btn btn-outline-primary" :to="{ name: 'users.new' }">Nuovo utente</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <div class="row justify-content-end align-items-center">
        <div class="col-auto text-right">
          <p>
            Risultati da mostrare
            <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xl-4 mb-4" v-for="user in users" :key="user.id">
          <UserCard :user="user"/>
        </div>
      </div>

      <div class="row justify-content-end align-items-center">
        <div class="col-auto text-right">
          <p>
            Risultati da mostrare
            <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
          </p>
        </div>
      </div>

      <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
    </div>
  </main>
</template>

<script>

import $api from '../../../libs/OAuth2/services/api';
import pageableMixin from '../../../libs/Pagination/mixins/pageable';
import confirmWithModalMixin from '../../../libs/Confirm/mixins/confirmWithModal';
import rolesMixin from '../../../mixins/roles';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listOperators()
    .then(res => next(vm => {
      vm.users = res?.data?.users || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  name: 'company-list',
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
    AwlPagination: () => import('../../../libs/Pagination/components/AwlPagination'),
    PerPageSelector: () => import('../../../libs/Pagination/components/PerPageSelector'),
    UserCard: () => import('./../../components/Card/UserCard'),
  },
  data () {
    return {
      users: [],
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'surname', label: 'Cognome' },
        { key: 'email', label: 'Email' },
      ];
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listOperators(page, this.perPage)
        .then(res => {
          this.users = res?.data?.users || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
